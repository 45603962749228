<template>
  <div>
    <v-overlay
        :z-index="100"
        :value="loading"
    >
      <v-progress-circular
          :size="70"
          :width="7"
          color="amber"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        v-if="!confirmation"
    >
      <v-container class="mt-8" style="max-width: 980px">
        <v-stepper
            v-model="e6"
            vertical
            elevation="0"
            class="pb-16"
        >
            <v-stepper-step
                :complete="e6 > 1"
                step="1"
                color="secondary"
            >
              <div class="px-8">{{ $t('step.eventdata') }}</div>
            </v-stepper-step>
            <v-stepper-content step="1">
              <div class="px-8">
                <h2 class="mb-2" style="font-size: 30px">{{ $t('eventform.basic_title') }}</h2>
                <p class="body-2" style="max-width: 510px">{{ $t('eventform.basic_text') }}</p>
                <v-spacer class="mb-4"></v-spacer>
                <v-form ref="detailsForm" v-model="detailsFormValid" lazy-validation>
                  <v-text-field
                      filled
                      rounded
                      counter
                      v-model="form.title"
                      :label="$t('eventform.title')"
                      :rules="[rules.required, rules.length30]"
                  ></v-text-field>
                  <v-text-field
                      outlined
                      counter
                      v-model="form.subtitle"
                      :label="$t('eventform.subtitle')"
                      :rules="[rules.required, rules.length30]"
                      style="display: none"
                  ></v-text-field>
                  <v-text-field
                      filled
                      rounded
                      v-model="form.name"
                      :rules="[rules.required, rules.length30]"
                      :label="$t('eventform.name')"
                      counter
                      required
                  ></v-text-field>
                  <v-text-field
                      filled
                      rounded
                      v-model="form.email"
                      :rules="[rules.required, rules.email]"
                      :label="$t('eventform.email')"
                      required
                  ></v-text-field>


                  <h2 class="mb-2 mt-4" style="font-size: 30px">{{ $t('eventform.location') }}</h2>
                  <p class="body-2" style="max-width: 510px">{{ $t('hints.location') }}</p>
                  <v-text-field
                      filled
                      rounded
                      v-model="form.location"
                      :label="$t('hints.location_address')"
                      :hint="$t('hints.location')"
                      required
                      :rules="[rules.required, rules.length100]"
                  ></v-text-field>


                  <h2 class="mb-2 mt-4" style="font-size: 30px">{{ $t('captions.date_time') }}</h2>
                  <p class="body-2" style="max-width: 510px">{{ $t('hints.date_time') }}</p>
                  <v-row class="px-3 mt-4 mb-6">
                    <div
                        class="px-8 py-4 mr-4 body-2 mt-4"
                        style="border: 1px #BFBFBF solid; cursor: pointer; border-radius: 35px"
                        @click="chooseDateType('single')"
                        v-if="!singleEvent"
                    >
                      {{ $t('eventform.single_event') }}
                    </div>
                    <div
                        class="px-8 py-4 mr-4 body-2 mt-4"
                        style="border: 1px #1294EA solid; background: #F7FAFC; cursor: pointer; border-radius: 35px"
                        v-if="singleEvent"
                    >
                      {{ $t('eventform.single_event') }}
                    </div>
                    <div
                        class="px-8 py-4 mr-4 body-2 mt-4"
                        style="border: 1px #BFBFBF solid; cursor: pointer; border-radius: 35px"
                        @click="chooseDateType('recurring')"
                        v-if="singleEvent"
                    >
                      {{ $t('eventform.recurring_event') }}
                    </div>
                    <div
                        class="px-8 py-4 mr-4 body-2 mt-4"
                        style="border: 1px #1294EA solid; background: #F7FAFC; cursor: pointer; border-radius: 35px"
                        v-if="!singleEvent"
                    >
                      {{ $t('eventform.recurring_event') }}
                    </div>
                  </v-row>
                  <p class="body-1 ml-4" style="max-width: 350px" v-if="!singleEvent">{{$t('eventform.recurring_event_text')}}</p>
                  <v-row v-if="singleEvent">
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-menu
                          ref="dateMenuStart"
                          v-model="dateMenuStart"
                          :close-on-content-click="false"
                          :return-value.sync="form.startDate"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                              v-bind="attrs"
                              v-on="on"
                          >
                            <v-text-field
                                v-model="formattedStartDate"
                                :label="$t('eventform.startDate')"
                                readonly
                                filled
                                rounded
                                :rules="[rules.required]"
                                @click="form.startDate = null"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                            v-model="form.startDate"
                            no-title
                            scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="primary"
                              @click="dateMenuStart = false"
                          >
                            {{ $t('eventform.cancel') }}
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.dateMenuStart.save(form.startDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                        cols="11"
                        sm="5"
                    >
                      <v-menu
                          ref="timeMenuStart"
                          v-model="timeMenuStart"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="form.timeStart"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                              v-bind="attrs"
                              v-on="on"
                          >
                            <v-text-field
                                v-model="form.timeStart"
                                :label="$t('eventform.timeStart')"
                                readonly
                                filled
                                rounded
                                :rules="[rules.required]"
                                @click="form.timeStart = null"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-time-picker
                            v-if="timeMenuStart"
                            format="24hr"
                            v-model="form.timeStart"
                            full-width
                            @click:minute="$refs.timeMenuStart.save(form.timeStart)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <v-row v-if="singleEvent" class="mt-0">
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-menu
                          ref="dateMenuEnd"
                          v-model="dateMenuEnd"
                          :close-on-content-click="false"
                          :return-value.sync="form.endDate"
                          transition="scale-transition"
                          offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                              v-bind="attrs"
                              v-on="on"
                          >
                            <v-text-field
                                style="margin-top: 0px !important;"
                                v-model="formattedEndDate"
                                :label="$t('eventform.endDate')"
                                readonly
                                filled
                                rounded
                                :rules="[rules.required]"
                                @click="form.endDate = null"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-date-picker
                            v-model="form.endDate"
                            no-title
                            scrollable
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                              text
                              color="primary"
                              @click="dateMenuEnd = false"
                          >
                            {{ $t('eventform.cancel') }}
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.dateMenuEnd.save(form.endDate)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                        cols="11"
                        sm="5"
                    >
                      <v-menu
                          ref="timeMenuEnd"
                          v-model="timeMenuEnd"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="form.timeEnd"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div
                              v-bind="attrs"
                              v-on="on"
                          >
                            <v-text-field
                                v-model="form.timeEnd"
                                :label="$t('eventform.timeEnd')"
                                readonly
                                filled
                                rounded
                                :rules="[rules.required]"
                                @click="form.timeEnd = null"
                            ></v-text-field>
                          </div>
                        </template>
                        <v-time-picker
                            v-if="timeMenuEnd"
                            format="24hr"
                            v-model="form.timeEnd"
                            full-width
                            @click:minute="$refs.timeMenuEnd.save(form.timeEnd)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-form>
              </div>
            </v-stepper-content>
















            <v-stepper-step
                :complete="e6 > 2"
                step="2"
                color="secondary"
            >
              <div class="px-8">{{ $t('step.media') }}</div>
            </v-stepper-step>
            <v-stepper-content step="2">
              <div class="px-8">
                <h2 class="mb-2 mt-4" style="font-size: 30px">{{ $t('eventform.title_image') }}</h2>
                <p class="body-2" style="max-width: 510px">{{ $t('eventform.title_image_description') }}</p>
                <v-form ref="mediaForm" v-model="mediaFormValid" lazy-validation>
                  <v-file-input
                      :label="$t('eventform.title_image_label')"
                      filled
                      rounded
                      accept="image/jpg, image/jpeg, image/png"
                      v-model="form.titleImg"
                      :rules="[rules.files,rules.required]"
                      @change="loadPicture()"
                      ref="imageUpload"
                  ></v-file-input>
                  <v-row v-if="form.titleImg">
                    <v-col>
                      <v-img
                          style="max-height:450px; border-radius: 5px;"
                          :src="imgSrc"
                          @click="selectImage"
                          @change="loadPicture()"
                      ></v-img>
                    </v-col>
                  </v-row>


                  <v-text-field
                      v-model="form.videoUrl"
                      :rules="[rules.length300]"
                      counter
                      filled
                      rounded
                      :label="$t('eventform.video_label')"
                      prepend-icon="mdi-video"
                      class="mb-4"
                      style="display: none"
                  ></v-text-field>

                  <h2 class="mb-2 mt-4" style="font-size: 30px">{{ $t('captions.description') }}</h2>
                  <p class="body-2" style="max-width: 510px">{{ $t('hints.description') }}</p>
                  <v-textarea
                      filled
                      rounded
                      counter
                      name="input-7-4"
                      v-model="form.description"
                      :rules="[rules.required, rules.length3000]"
                      :label="$t('captions.description')"
                      :hint="$t('hints.description')"
                      style="display: none"
                  ></v-textarea>
                  <vue-editor
                      v-model="form.description"
                      :editorOptions="editorSettings"
                      :text-change="checkDescription()"
                  ></vue-editor>
                  <p class="error--text">{{descriptionError}}</p>
                </v-form>
              </div>
            </v-stepper-content>














            <v-stepper-step
                :complete="e6 > 3"
                step="3"
                color="secondary"
            >
              <div class="px-8">{{ $t('step.tickets') }}</div>
            </v-stepper-step>
            <v-stepper-content step="3">
              <div class="px-8">
                <h2 class="mb-2 mt-4" style="font-size: 30px">{{ $t('eventform.ticket_types_title') }}</h2>
                <p class="body-2" style="max-width: 510px">{{ $t('eventform.ticket_types_description') }}</p>
                <v-row class="px-3 mt-4 mb-6">
                  <div
                      class="px-8 py-4 mr-4 body-2 mt-4"
                      style="border: 1px #BFBFBF solid; cursor: pointer; border-radius: 35px"
                      @click="chooseTicketType('payed')"
                      v-if="!payedTicket"
                  >
                    {{ $t('eventform.payed_ticket') }}
                  </div>
                  <div
                      class="px-8 py-4 mr-4 body-2 mt-4"
                      style="border: 1px #1294EA solid; background: #F7FAFC; cursor: pointer; border-radius: 35px"
                      v-if="payedTicket"
                  >
                    {{ $t('eventform.payed_ticket') }}
                  </div>
                  <div
                      class="px-8 py-4 mr-4 body-2 mt-4"
                      style="border: 1px #BFBFBF solid; cursor: pointer; border-radius: 35px"
                      @click="chooseTicketType('free')"
                      v-if="!freeTicket"
                  >
                    {{ $t('eventform.free_ticket') }}
                  </div>
                  <div
                      class="px-8 py-4 mr-4 body-2 mt-4"
                      style="border: 1px #1294EA solid; background: #F7FAFC; cursor: pointer; border-radius: 35px"
                      v-if="freeTicket"
                  >
                    {{ $t('eventform.free_ticket') }}
                  </div>
                </v-row>
                <v-form ref="ticketForm" v-model="ticketFormValid" lazy-validation style="max-width: 500px">
                  <v-text-field
                      :label="$t('eventform.ticketname')"
                      v-model="newTicketType.name"
                      :rules="[rules.required, rules.length50]"
                      :hint="$t('eventform.ticketname_hint')"
                      filled
                      rounded
                  ></v-text-field>
                  <v-text-field
                      :label="$t('eventform.count')"
                      v-model="newTicketType.quantity"
                      :rules="[rules.pinteger]"
                      :hint="$t('eventform.count_hint')"
                      filled
                      rounded
                  ></v-text-field>
                  <v-text-field
                      :label="$t('eventform.price')"
                      v-model="newTicketType.price"
                      :rules="[rules.pfloat]"
                      prefix="€"
                      filled
                      rounded
                      v-if="this.payedTicket"
                  ></v-text-field>
                  <v-btn
                      class="mb-4"
                      dark
                      color="blue lighten-1"
                      @click="addTicketType()"
                  >
                    {{ $t('eventform.ticket_type_add') }}
                  </v-btn>
                  <div class="body-2 error--text" v-if="showNoTicketError">{{$t('eventform.min_ticket_types')}}</div>
                </v-form>

                <div v-if="getDisplaySize() !== 'xs' ">
                  <div v-for="type in form.ticketTypes" :key="type.id"
                       style="background: #F7FAFC; border: 1px solid #1294EA"
                       class="rounded px-8 py-4 mt-4"
                  >
                    <v-row>
                      <v-col
                        cols="6"
                      >
                        <small>{{ $t('eventform.ticketname') }}</small>
                        <p class="body-1 font-weight-bold mb-0">{{type.name}}</p>
                      </v-col>
                      <v-col class="text-right">
                        <small>{{ $t('eventform.count') }}</small>
                        <p  v-if="type.quantity !== null"
                            class="body-1 font-weight-bold mb-0">
                          {{type.quantity}} x
                        </p>
                        <p  v-if="type.quantity === null"
                            class="body-1 font-weight-bold mb-0">
                          {{ $t('eventform.infinite') }}
                        </p>
                      </v-col>
                      <v-col class="text-right">
                        <small>{{ $t('eventform.price') }}</small>
                        <p  v-if="type.price !== null && type.price !== 0"
                            class="body-1 font-weight-bold mb-0">
                          € {{type.price}}
                        </p>
                        <p  v-if="type.price === null || type.price === 0"
                            class="body-1 font-weight-bold mb-0">
                          {{ $t('eventform.free') }}
                        </p>
                      </v-col>
                      <v-col
                          class="text-right"
                          style="cursor: pointer;"
                          @click="removeTicketType(type.id)"
                      >
                        <div class="pt-2" style="font-size: 23px;">
                          <font-awesome-icon :icon="['fa', 'trash']" />
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <div v-if="getDisplaySize() === 'xs' ">
                  <div v-for="type in form.ticketTypes" :key="type.id"
                        class=" my-4"
                  >
                    <v-row>
                      <v-col cols="8" style="background: #F7FAFC; border: 1px solid #1294EA"
                             class="rounded px-4 py-4">
                        <v-row>
                          <v-col>
                            <small>Bezeichnung</small>
                            <p class="body-1 font-weight-bold mb-0">{{type.name}}</p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <small>Anzahl</small>
                            <p  v-if="type.quantity !== null"
                                class="body-1 font-weight-bold mb-0">
                              {{type.quantity}} x
                            </p>
                            <p  v-if="type.quantity === null"
                                class="body-1 font-weight-bold mb-0">
                              Unendlich
                            </p>
                          </v-col>
                          <v-col>
                            <small>Preis</small>
                            <p  v-if="type.price !== null && type.price !== 0"
                                class="body-1 font-weight-bold mb-0">
                              € {{type.price}}
                            </p>
                            <p  v-if="type.price === null || type.price === 0"
                                class="body-1 font-weight-bold mb-0">
                              Gratis
                            </p>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                          class="text-right d-flex justify-center rounded px-4 py-4"
                          style="cursor: pointer; background: #fff; border: 1px solid #1294EA; margin-left: -1px"
                          @click="removeTicketType(type.id)"
                      >
                          <div style="font-size: 35px;"
                               class="pt-8">
                            <font-awesome-icon :icon="['fa', 'trash']" />
                          </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>



              </div>
            </v-stepper-content>

















            <v-stepper-step
                step="4"
                color="secondary"
            >
              <div class="px-8">{{ $t('step.crowdfunding') }}</div>
            </v-stepper-step>
            <v-stepper-content step="4"
                               style="max-width: 900px">
              <div class="px-8">
                <h2 class="mb-2 mt-4" style="font-size: 30px">{{ $t('eventform.crowdfunding.header') }}</h2>
                <p class="body-2" style="max-width: 510px">{{ $t('eventform.crowdfunding.text') }}</p>
                <v-row class="px-3 mt-4 mb-6">
                  <div
                      class="px-8 py-4 mr-4 body-2 text-center xs-mt-4"
                      style="border: 1px #BFBFBF solid; cursor: pointer; border-radius: 15px; max-width: 300px"
                      @click="chooseCampaignType('crowdfunding')"
                      v-if="!crowdfunding"
                  >
                    <h3 class="mb-2">{{ $t('eventform.crowdfunding.crowdfunding_header') }}</h3>
                    <p class="body-2 mb-0">{{ $t('eventform.crowdfunding.crowdfunding_text') }}</p>
                  </div>
                  <div
                      class="px-8 py-4 mr-4 body-2 text-center xs-mt-4"
                      style="border: 1px #1294EA solid; background: #F7FAFC; cursor: pointer; border-radius: 15px; max-width: 300px"
                      v-if="crowdfunding"
                  >
                    <h3 class="mb-2">{{ $t('eventform.crowdfunding.crowdfunding_header') }}</h3>
                    <p class="body-2 mb-0">{{ $t('eventform.crowdfunding.crowdfunding_text') }}</p>
                  </div>
                  <div
                      class="px-8 py-4 mr-4 body-2 text-center xs-mt-4"
                      style="border: 1px #BFBFBF solid; cursor: pointer; border-radius: 15px; max-width: 300px"
                      @click="chooseCampaignType('noCrowdfunding')"
                      v-if="crowdfunding"
                  >
                    <h3 class="mb-2">{{ $t('eventform.crowdfunding.no_crowdfunding_header') }}</h3>
                    <p class="body-2 mb-0">{{ $t('eventform.crowdfunding.no_crowdfunding_text') }}</p>
                  </div>
                  <div
                      class="px-8 py-4 mr-4 body-2 text-center xs-mt-4"
                      style="border: 1px #1294EA solid; background: #F7FAFC; cursor: pointer; border-radius: 15px; max-width: 300px"
                      v-if="!crowdfunding"
                  >
                    <h3 class="mb-2">{{ $t('eventform.crowdfunding.no_crowdfunding_header') }}</h3>
                    <p class="body-2 mb-0">{{ $t('eventform.crowdfunding.no_crowdfunding_text') }}</p>
                  </div>
                </v-row>
                <v-row class="px-3 mt-4 mb-6" v-if="crowdfunding">
                  <div
                      class="px-8 py-4 mr-4 body-2 text-center xs-mt-4"
                      style="border: 1px #BFBFBF solid; cursor: pointer; border-radius: 12px; max-width: 300px"
                      @click="chooseFundingType('price')"
                      v-if="this.form.fundingType !== 'price'"
                  >
                    {{ $t('eventform.crowdfunding.revenue_text') }}
                  </div>
                  <div
                      class="px-8 py-4 mr-4 body-2 text-center xs-mt-4"
                      style="border: 1px #1294EA solid; background: #F7FAFC; cursor: pointer; border-radius: 12px; max-width: 300px"
                      v-if="this.form.fundingType === 'price'"
                  >
                    {{ $t('eventform.crowdfunding.revenue_text') }}
                  </div>
                  <div
                      class="px-8 py-4 mr-4 body-2 text-center xs-mt-4"
                      style="border: 1px #BFBFBF solid; cursor: pointer; border-radius: 12px; max-width: 300px"
                      @click="chooseFundingType('quantity')"
                      v-if="this.form.fundingType !== 'quantity'"
                  >
                    {{ $t('eventform.crowdfunding.count_text') }}
                  </div>
                  <div
                      class="px-8 py-4 mr-4 body-2 text-center xs-mt-4"
                      style="border: 1px #1294EA solid; background: #F7FAFC; cursor: pointer; border-radius: 12px; max-width: 300px"
                      v-if="this.form.fundingType === 'quantity'"
                  >
                    {{ $t('eventform.crowdfunding.count_text') }}
                  </div>
                </v-row>


                <v-text-field
                    v-if="form.fundingType === 'quantity'"
                    :label="$t('eventform.quantity_label')"
                    v-model="form.fundingQuantity"
                    :rules="[rules.pinteger]"
                    value="0"
                    :hint="$t('eventform.quantity_hint')"
                    filled
                    rounded
                ></v-text-field>
                <v-text-field
                    v-if="form.fundingType === 'price'"
                    :label="$t('eventform.revenue_label')"
                    v-model="form.fundingPrice"
                    :rules="[rules.pfloat]"
                    value="0.00"
                    prefix="€"
                    :hint="$t('eventform.revenue_hint')"
                    filled
                    rounded
                ></v-text-field>
                <v-menu
                    v-if="form.fundingType === 'price' || form.fundingType === 'quantity'"
                    ref="dateMenuPresaleEnd"
                    v-model="dateMenuPresaleEnd"
                    :close-on-content-click="false"
                    :return-value.sync="form.endPresaleDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-text-field
                          v-model="formattedEndPresaleDate"
                         :label="$t('eventform.end_campaign_label')"
                          readonly
                          filled
                          rounded
                          :rules="[rules.required]"
                          @click="form.endPresaleDate = null"
                      ></v-text-field>
                    </div>
                  </template>
                  <v-date-picker
                      v-model="form.endPresaleDate"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="dateMenuPresaleEnd = false"
                    >
                      {{ $t('eventform.cancel') }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.dateMenuPresaleEnd.save(form.endPresaleDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>

                <h4 class="mb-2">{{ $t('eventform.crowdfunding.flyer_title') }}</h4>
                <div
                    class="px-8 py-4 mr-4 body-2 text-center"
                    style="border: 1px #1294EA solid; background: #F7FAFC; cursor: pointer; border-radius: 15px; max-width: 300px"
                >
                  <h3 class="mb-2">{{ $t('eventform.crowdfunding.flyer_header') }}</h3>
                  <p class="body-2 mb-0">{{ $t('eventform.crowdfunding.flyer_text') }}</p>
                </div>

                <h2 class="mb-2 mt-8" style="font-size: 30px">{{ $t('eventform.crowdfunding.other_header') }}</h2>
                <p class="body-2" style="max-width: 510px">{{ $t('eventform.crowdfunding.other_text') }}</p>
                <v-form ref="contactForm" v-model="contactFormValid" lazy-validation>
                  <v-textarea
                      v-model="form.note"
                      :label="$t('eventform.note')"
                      :hint="$t('eventform.form_note')"
                      :rules="[rules.length600]"
                      counter
                      class="mb-4"
                      filled
                      rounded
                  ></v-textarea>
                </v-form>
              </div>
            </v-stepper-content>
        </v-stepper>

      </v-container>
    </v-form>

    <div
      style="
      position: fixed;
      bottom: 0;
      height: 80px;
      width: 100%;
      border-top: 1px lightgrey solid;
      background: white"
    >
      <div style="max-width: 900px" class="mx-auto">
        <div class="float-right mt-3 mx-8">
          <v-btn
              v-if="e6 !== 1"
              text
              @click="e6 = e6 - 1"
              x-large
          >
            {{ $t('eventform.back') }}
          </v-btn>
          <v-btn
              v-if="e6 !== 4"
              color="secondary"
              @click="checkAndStep(e6 + 1)"
              x-large
              class="ml-4"
          >
            {{ $t('eventform.continue') }}
          </v-btn>
          <v-dialog
              v-if="e6 === 4"
              v-model="dialog"
              persistent
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="secondary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  x-large
              >
                <span v-if="getDisplaySize() === 'xs'">{{ $t('eventform.send_short') }}</span>
                <span v-if="getDisplaySize() !== 'xs'">{{ $t('eventform.send') }}</span>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">
                {{ $t('eventform.dialog_title') }}
              </v-card-title>
              <v-card-text>
                {{ $t('eventform.dialog_subtitle') }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="grey darken-1"
                    text
                    @click="dialog = false"
                >
                  {{ $t('eventform.back') }}
                </v-btn>
                <v-btn
                    color="grey darken-3"
                    text
                    @click.prevent="submitForm"
                    :disabled="!valid"
                >
                  {{ $t('eventform.send_short') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>

    </div>

    <v-snackbar
        v-model="snackbar"
        :timeout=5000
        absolute
        bottom
        :color=snackbarcolor
    >
      <h3 v-html="response"></h3>
    </v-snackbar>

  </div>

</template>

<script>
import {validationRules} from '@/utils/validation_rules';
import * as helpers from "@/utils/helpers";
import axios from "axios";
import * as config from "@/api/config";
import {VueEditor} from 'vue2-editor';

export default {
  components: {
    VueEditor
  },
  metaInfo() {
    return {
      title: this.$t('meta.createevent.title'),
      meta: [
        {property: 'og:site_name', content: this.$t('meta.createevent.og:site_name')}
      ]
    }
  },
  data() {
    return {
      payedTicket: true,
      singleEvent: true,
      freeTicket: false,
      crowdfunding: true,
      quantity: false,
      price: true,
      descriptionError: "",
      editorSettings: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],        // toggled buttons
            ['link'],

            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }]
          ]
        }
      },
      banner: true,
      loading: false,
      confirmation: false,
      valid: null,
      showNoTicketError: false,
      detailsFormValid: null,
      mediaFormValid: null,
      contactFormValid: null,
      ticketFormValid: null,
      e6: 1,
      ticketTypeCount: 1,
      snackbar: false,
      snackbarcolor: 'success',
      dialog: false,
      imgSrc: null,
      dateMenuStart: false,
      timeMenuStart: false,
      dateMenuEnd: false,
      timeMenuEnd: false,
      dateMenuPresaleEnd: false,
      form: {
        title: null,
        subtitle: null,
        location: null,
        startDate: null,
        timeStart: null,
        endDate: null,
        timeEnd: null,
        description: null,
        name: null,
        email: null,
        note: null,
        titleImg: null,
        videoUrl: null,
        fundingType: null,
        fundingQuantity: null,
        fundingPrice: null,
        endPresaleDate: null,
        ticketTypes: null,
      },
      newTicketType: {name: null, quantity: null, price: 0.00},
      rules: validationRules,
      response: '',
    }
  },

  computed: {
    formattedStartDate: {
      get() {
        return helpers.formatDate(this.form.startDate)
      },
      set(v) {
        this.form.startDate = v
      }
    },
    formattedEndDate: {
      get() {
        return helpers.formatDate(this.form.endDate)
      },
      set(v) {
        this.form.endDate = v
      }
    },
    formattedEndPresaleDate: {
      get() {
        return helpers.formatDate(this.form.endPresaleDate)
      },
      set(v) {
        this.form.endPresaleDate = v
      }
    }
  },

  methods: {
    getDisplaySize(){
      return this.$vuetify.breakpoint.name;
    },

    checkDescription() {
      if (this.form.description != null && this.form.description.length > 3000){
        this.descriptionError = this.$t('eventform.long_description_error')
      } else if (this.form.description == null || this.form.description.length < 1){
        this.descriptionError = this.$t('eventform.no_description_error')
      } else {
        this.descriptionError = ""
      }
    },

    chooseTicketType(type){
      if (type === "free") {
        this.freeTicket = true
        this.payedTicket = false
        this.newTicketType.price = 0
      } else if (type === "payed") {
        this.freeTicket = false
        this.payedTicket = true
      }
    },

    chooseDateType(type){
      if (type === "single") {
        this.singleEvent = true;
      } else if (type === "recurring") {
        this.singleEvent = false;
        if (this.form.startDate === null){
          this.form.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        }
        if (this.form.timeStart === null){
          this.form.timeStart = '20:00';
        }
        if (this.form.endDate === null){
          this.form.endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        }
        if (this.form.timeEnd === null){
          this.form.timeEnd = '22:00';
        }
      }
    },

    chooseCampaignType(type){
      if (type === "crowdfunding") {
        this.crowdfunding = true
        this.form.fundingType = "price"
      } else if (type === "noCrowdfunding") {
        this.crowdfunding = false
        this.form.fundingType = "none"
      }
    },

    chooseFundingType(type){
      if (type === "price") {
        this.form.fundingType = "price"
      } else if (type === "quantity") {
        this.form.fundingType = "quantity"
      }
    },

    addTicketType() {
      if (this.$refs.ticketForm.validate()) {
        this.ticketTypeCount++;
        this.form.ticketTypes.push({
          name: this.newTicketType.name,
          quantity: this.newTicketType.quantity,
          price: this.newTicketType.price,
          id: this.ticketTypeCount
        });
        this.newTicketType = {name: "Ticket", price: 0, quantity: null}
        this.showNoTicketError = false;
      }
    },

    removeTicketType(id) {
      var index = this.form.ticketTypes.map(x => {
        return x.id;
      }).indexOf(id);

      this.form.ticketTypes.splice(index, 1);
    },

    loadPicture() {
      let self = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        self.imgSrc = e.target.result;
      }
      reader.readAsDataURL(this.form.titleImg);
    },

    selectImage () {
      this.$refs.imageUpload.$refs.input.click();
    },

    checkAndStep(no) {
      if (no === 2) {
        if (this.$refs.detailsForm.validate()) {
          this.e6 = no
          window.analytics.track('Passed First Step Event Creation');
        } else {
          window.analytics.track('Error on First Step Event Creation', {
            email: this.form.email,
            name: this.form.name,
            title: this.form.title,
            startDate: this.form.startDate,
            endDate: this.form.endDate,
            startTime: this.form.timeStart,
            endTime: this.form.timeEnd
          });
        }
      } else if (no === 3) {
        if (this.$refs.mediaForm.validate() && this.form.description != null && this.form.description.length > 1) {
          this.e6 = no
          window.analytics.track('Passed Second Step Event Creation');
        } else {
          window.analytics.track('Error on Second Step Event Creation', {
            description: this.form.description
          });
        }
      } else if (no === 4) {
        if (this.$refs.ticketForm.validate()) {
          if (this.form.ticketTypes.length === 0){
            this.showNoTicketError = true;
            window.analytics.track('Error on Third Step Event Creation', {
              tickets: this.form.ticketTypes
            });
          } else {
            this.e6 = no
            this.showNoTicketError = false;
            window.analytics.track('Passed Third Step Event Creation');
          }
        } else {
          window.analytics.track('Error on Third Step Event Creation', {
            tickets: this.form.ticketTypes
          });
        }
      }
    },

    submitForm() {
      this.dialog = false;
      this.loading = true;

      if (this.form.fundingPrice == "") {
        this.form.fundingPrice = 0;
      }
      if (this.form.fundingQuantity == "") {
        this.form.fundingQuantity = 0;
      }

      if (!this.singleEvent){
        this.form.description = this.form.description + " (wiederkehrendes Event)"
      }

      if (this.$refs.contactForm.validate()) {

        const formData = helpers.generateFormData(this.form)

        this.snackbarcolor = 'success';
        axios.post(config.KUMSCHO_API_SERVER + '/mail/createevent', formData).then(response => {
          this.displayResponse(response.data)
          if (this.confirmation) {
            window.analytics.track('Event Creation Successful');
            this.$router.push({
              name: 'EventConfirmation',
              params: {
                eventData: this.form,
                imgSrc: this.imgSrc
              }
            })
          }
        }).catch(error => {
          let msg = error.message ? error.message : error.response.statusText
          this.displayResponse('Error: ' + msg)
          window.analytics.track('Error on Fourth Step Event Creation', {
            tickets: this.form
          });
        }).finally(() => {
          this.snackbar = true
          this.loading = false;
        });
      } else {
        window.analytics.track('Error on Fourth Step Event Creation', {
          tickets: this.form
        });
        this.loading = false;
      }
    },

    displayResponse(res) {
      if (res == 'Ok') {
        res = 'Event erfolgreich eingereicht!'
        //this.$refs.form.reset()
        this.confirmation = true
      } else this.snackbarcolor = 'error';
      this.response = res;
    },

    backToCreateEvent() {
      this.confirmation = false
      this.e6 = 1
      this.resetForm()
    },

    resetForm() {
      this.form.name = null,
      this.form.email = null,
      this.form.note = null,
      this.form.title = null,
      this.form.subtitle = "keine Angabe",
      this.form.location = null,
      this.form.startDate = null,
      this.form.timeStart = null,
      this.form.endDate = null,
      this.form.timeEnd = null,
      this.form.titleImg = null,
      this.form.videoUrl = null,
      this.form.description = null,
      this.form.fundingType = 'price',
      this.form.fundingQuantity = 0,
      this.form.fundingPrice = 1000,
      this.form.endPresaleDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      this.form.ticketTypes = []
    },
  },
  mounted() {
    window.analytics.track('Visited Event Creation Page')
    this.resetForm();
  }

}
</script>

<style scoped>
.ql-snow .ql-tooltip {
  z-index: 100;
}

@media screen and (max-width: 800px){
  .v-application--is-ltr .v-stepper--vertical .v-stepper__content{
    margin-left: 0;
  }

  .v-application--is-ltr .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
    border-left: 0px;
  }

  .v-stepper--vertical .v-stepper__content{
    padding-left: 0px;
    padding-right: 20px;
  }

  .xs-mt-4 {
    margin-top: 12px;
  }
}
</style>